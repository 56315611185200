// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-archive-js": () => import("./../src/templates/archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-theatre-js": () => import("./../src/templates/theatre.js" /* webpackChunkName: "component---src-templates-theatre-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

